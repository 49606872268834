import priya from "../assets/TeamImages/priya.jpg";
import sundhar from "../assets/TeamImages/sundar.jpg";
import sat from "../assets/TeamImages/sat.jpg";
import partha from "../assets/TeamImages/partha.jpg";
import vinoth from "../assets/TeamImages/vinoth_E.jpg";
import vignesh from "../assets/TeamImages/vicky10.jpg";
import arudhra from "../assets/TeamImages/arudhra_2.jpeg";
import vinith from "../assets/TeamImages/vinith_2.jpg";

import vinotha from "../assets/TeamImages/vinotha5.jpg";
import vignesh_v from "../assets/TeamImages/vigneshV.jpg";
import arun from "../assets/TeamImages/arun2.jpg";
import surej from "../assets/TeamImages/surej7.jpg";
import mukesh from "../assets/TeamImages/mukesh2.jpg";

import dinesh from "../assets/TeamImages/Dinesh.jpg";
import vinoth_s from "../assets/TeamImages/vinoth.jpg";
import prakash from "../assets/TeamImages/prakash.jpg";
import arunachalam from "../assets/TeamImages/arunachalam.jpg";
import rajasekar from "../assets/TeamImages/Rajasekar.jpg";
import kavibalan from "../assets/TeamImages/Kavibalan.png";
import prem from "../assets/TeamImages/prem_kumar.jpg";
import murali from "../assets/TeamImages/Murali.jpg";

import jay from "../assets/TeamImages/Jay.jpg";

import arunKumar from "../assets/TeamImages/arun3.jpg";
import pratheeks from "../assets/TeamImages/pratheeks.jpg";
import akshaya from "../assets/TeamImages/akshaya.jpg";
import sajitha from "../assets/TeamImages/sajitha.jpg";

export const teamLabel = "Team";

export const teamItems = [
  { name: "Sundar", icon: sundhar, color: "", role: "CEO" },
  { name: "Sat", icon: sat, color: "#FF69B4", role: "" },
  { name: "Partha", icon: partha, color: "", role: "Quality Assurance" },
  { name: "Priya", icon: priya, color: "red", role: "developer" },
  { name: "Vinoth", icon: vinoth, color: "blue", role: "developer" },
  { name: "Arudhra", icon: arudhra, color: "black", role: "developer" },
  { name: "Vinith", icon: vinith, color: "black", role: "developer" },
  { name: "Jayaseelan", icon: jay, color: "red", role: "animation" },

  { name: "Vinotha", icon: vinotha, color: "", role: "developer" },
  { name: "Vignesh", icon: vignesh, color: "red", role: "developer" },
  { name: "Arun", icon: arun, color: "black", role: "developer" },
  { name: "Vignesh", icon: vignesh_v, color: "blue", role: "developer" },
  { name: "Surej", icon: surej, color: "black", role: "developer" },
  { name: "Mukesh", icon: mukesh, color: "red", role: "developer" },

  { name: "Vinoth", icon: vinoth_s, color: "blue", role: "developer" },
  { name: "Dinesh Kumar", icon: dinesh, color: "lavender", role: "developer" },
  { name: "Prakash", icon: prakash, color: "black", role: "developer" },
  { name: "Arunachalam", icon: arunachalam, color: "black", role: "developer" },
  { name: "Premkumar", icon: prem, color: "blue", role: "developer" },
  { name: "Rajasekar", icon: rajasekar, color: "red", role: "developer" },
  { name: "Kavibalan", icon: kavibalan, color: "blue", role: "developer" },
  { name: "Murali", icon: murali, color: "red", role: "Trainer" },
  { name: "Arun Kumar", icon: arunKumar, color: "red", role: "animation" },
  { name: "Pratheeks", icon: pratheeks, color: "blue", role: "developer" },
  { name: "Sajitha", icon: sajitha, color: "red", role: "Trainer" },
  { name: "Akshaya", icon: akshaya, color: "red", role: "hr" },
];
